import { createAsync, useLocation } from '@solidjs/router';
import { ErrorBoundary, Show, Suspense } from 'solid-js';
import { Title } from '@solidjs/meta';
import { BreadcrumbItem, Breadcrumbs, Container, Heading, Page, Section } from '@troon/ui';
import { createContentfulRequest } from '../../content/[model]/_client';
import { NotFoundContent } from '../../../partials/404';
import { RichText } from '../../../components/rich-text';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function EventTermsAndConditions(props: RouteSectionProps) {
	const event = createAsync(async () => getEvent(props.params.slug!), { deferStream: true });
	const loc = useLocation();

	return (
		<ErrorBoundary fallback={<NotFoundContent />}>
			<Suspense>
				<Show when={event()?.fields?.termsConditions?.fields} fallback={<NotFoundContent />}>
					{(terms) => (
						<>
							<Title>Terms & Conditions | {event()!.fields.title} | Events | Troon</Title>
							<Container class="pt-4">
								<Breadcrumbs>
									<BreadcrumbItem href="/events">Events</BreadcrumbItem>
									<BreadcrumbItem href={loc.pathname.replace('/terms-and-conditions', '')}>
										{event()!.fields.title}
									</BreadcrumbItem>
									<BreadcrumbItem href={loc.pathname}>Terms & Conditions</BreadcrumbItem>
								</Breadcrumbs>
							</Container>
							<Container size="small" class="flex flex-col gap-8">
								<Page>
									<Section>
										<Heading as="h1">{terms().title}</Heading>
										<RichText document={terms().content} />
									</Section>
								</Page>
							</Container>
						</>
					)}
				</Show>
			</Suspense>
		</ErrorBoundary>
	);
}

const getEvent = createContentfulRequest('events', {});

export const route = {
	info: { nav: { hero: false } },
	preload: async ({ params }) => getEvent(params.slug!),
} satisfies RouteDefinition;
