import dayjs from '@troon/dayjs';
import { Title } from '@solidjs/meta';
import { createAsync } from '@solidjs/router';
import { createMemo, createSignal, ErrorBoundary, For, Match, Show, Suspense, Switch } from 'solid-js';
import {
	Button,
	Container,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	HorizontalRule,
	Link,
	Picture,
	Section,
	TextLink,
} from '@troon/ui';
import { IconCalendar } from '@troon/icons/calendar';
import { IconTag } from '@troon/icons/tag';
import { IconUserCircle } from '@troon/icons/user-circle';
import { useTrackEvent } from '@troon/analytics';
import { createContentfulRequest } from '../../content/[model]/_client';
import { Hero } from '../../../components/hero/photo';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { RichText } from '../../../components/rich-text';
import { useUser } from '../../../providers/user';
import { AuthFlow } from '../../../partials/auth/auth';
import { NotFoundContent } from '../../../partials/404';
import { createDollarFormatter } from '../../../modules/number-formatting';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function EventPage(props: RouteSectionProps) {
	const user = useUser();
	const event = createAsync(async () => getEvent(props.params.slug!), { deferStream: true });
	const [showAuthDialog, setShowAuthDialog] = createSignal(false);
	const dollarFormatter = createDollarFormatter();
	const trackEvent = useTrackEvent();

	const displayDate = createMemo(() => {
		const start = event()?.fields.startDate;
		const end = event()?.fields.endDate;
		if (!start || !end) {
			return undefined;
		}

		const startDate = dayjs(start);
		const endDate = dayjs(end);
		if (startDate.isSame(endDate, 'day')) {
			return startDate.format('MMMM D, YYYY');
		}
		const startFormat = startDate.isSame(endDate, 'year') ? 'MMMM D' : 'MMMM D, YYYY';
		return `${startDate.format(startFormat)} – ${endDate.format('MMMM D, YYYY')}`;
	});

	const registrationState = createMemo(() => {
		const today = dayjs();

		const { startDate, endDate, registrationState } = event()?.fields ?? {};
		if (!startDate || !endDate || typeof registrationState === 'undefined') {
			return undefined;
		}

		if (today.isBefore(startDate)) {
			return 'too-early';
		}
		if (today.isAfter(endDate)) {
			return 'closed';
		}
		if (!registrationState) {
			return 'closed';
		}
		return 'open';
	});

	return (
		<ErrorBoundary fallback={<NotFoundContent withHero />}>
			<Suspense>
				<Show when={event()?.fields} fallback={<NotFoundContent withHero />}>
					{(event) => (
						<>
							<Title>{event().title} | Event | Troon</Title>
							<Hero src={event().hero?.fields?.file?.url}>
								<Heading as="h1">{event().title}</Heading>
								<p class="order-first">{displayDate()}</p>
								<p>
									<Show when={event().locationName}>{(name) => <>{name()} - </>}</Show>
									{event().locationAddress}
								</p>
							</Hero>
							<Container>
								<Grid>
									<GridSeven class="flex flex-col gap-12">
										<Section>
											<Heading as="h2" size="h4">
												Overview
											</Heading>
											<RichText document={event().overview} headingOffset={2} />
										</Section>

										<HorizontalRule />

										<Show when={event().whatsIncluded}>
											{(included) => (
												<>
													<Section>
														<Heading as="h2" size="h4">
															What’s Included
														</Heading>
														<ul class="flex list-disc flex-col gap-1 ps-6">
															<For each={included()}>{(item) => <li>{item}</li>}</For>
														</ul>
													</Section>
													<HorizontalRule />
												</>
											)}
										</Show>

										<Show when={event().itinerary}>
											{(itinerary) => (
												<>
													<Section>
														<Heading as="h2" size="h4">
															Itinerary
														</Heading>
														<RichText document={itinerary()} headingOffset={2} />
													</Section>
													<HorizontalRule />
												</>
											)}
										</Show>

										<Show when={event().registrationRules}>
											{(rules) => (
												<>
													<Section>
														<Heading as="h2" size="h4">
															Registration Rules
														</Heading>
														<ul class="flex list-disc flex-col gap-1 ps-6">
															<For each={rules()}>{(item) => <li>{item}</li>}</For>
														</ul>
													</Section>
													<HorizontalRule />
												</>
											)}
										</Show>

										<Show when={event().location}>
											<Section>
												<Heading as="h2" size="h4">
													Location
												</Heading>

												<Picture
													src={
														!props.location.query.preview
															? `https://${getConfigValue('MAP_HOST')}/events/${props.params.slug!}/map.png`
															: `https://${getConfigValue('HOST')}/events/${props.params.slug!}/map.png?preview=1`
													}
													alt=""
													width={800}
													height={400}
													sizes="(min-width: 1024px) 20vw, 95vw"
													class="rounded"
													loading="lazy"
												/>

												<p class="flex flex-col gap-px">
													<Show when={event().locationName}>{(name) => <b>{name()}</b>}</Show>
													<Show when={event().locationAddress}>{(addr) => <span>{addr()}</span>}</Show>
												</p>
											</Section>
										</Show>
									</GridSeven>

									<GridFive>
										<div class="sticky top-24 flex flex-col gap-4">
											<Section appearance="contained">
												<Heading as="h2" size="h4">
													<Switch fallback="Registration Closed">
														<Match when={registrationState() === 'too-early'}>Registration Opens Soon!</Match>
														<Match when={registrationState() === 'open'}>Register Now</Match>
													</Switch>
												</Heading>

												<HorizontalRule />

												<ul class="flex flex-col gap-3">
													<li class="flex gap-3">
														<IconCalendar class="size-6 text-brand" /> {displayDate()}
													</li>
													<li class="flex gap-3">
														<IconTag class="size-6 text-brand" />{' '}
														{!event().minPrice && !event().maxPrice
															? 'Free'
															: event().minPrice && event().maxPrice && event().minPrice !== event().maxPrice
																? dollarFormatter().formatRange(event().minPrice!, event().maxPrice!)
																: dollarFormatter().format(event().minPrice || event().maxPrice!)}{' '}
														{event().priceDescription}
													</li>
													<Switch>
														<Match when={event().registrationRequirements === 'Troon Access'}>
															<li class="flex gap-3">
																<IconUserCircle class="size-6 text-brand" /> Troon Access Membership Required
															</li>
														</Match>
														<Match when={event().registrationRequirements === 'Troon Access+'}>
															<li class="flex gap-3">
																<IconUserCircle class="size-6 text-brand" /> Troon Access+ Membership Required
															</li>
														</Match>
														<Match when={event().registrationRequirements === 'Troon Rewards Account'}>
															<li class="flex gap-3">
																<IconUserCircle class="size-6 text-brand" /> Troon Account Required
															</li>
														</Match>
													</Switch>
												</ul>

												<Show when={registrationState() !== 'closed'}>
													<Show
														when={user()}
														fallback={
															<Dialog
																key="reserve-login-signup-link"
																open={showAuthDialog()}
																onOpenChange={setShowAuthDialog}
															>
																<DialogTrigger>Log in to Register</DialogTrigger>
																<DialogContent width="fit" noPadding closeButton="text-white">
																	<AuthFlow
																		onComplete={() => setShowAuthDialog(false)}
																		headings={{ '/auth': 'Log in or sign up to register' }}
																	/>
																</DialogContent>
															</Dialog>
														}
													>
														<Button
															as={registrationState() === 'open' ? Link : undefined}
															href={registrationState() === 'open' ? event().registrationUrl : ''}
															disabled={
																dayjs().isBefore(event().registrationStartDate) ||
																dayjs().isAfter(event().registrationEndDate)
															}
															onClick={() => trackEvent('eventRegistrationClick', { event: props.params.slug! })}
														>
															Register Now
														</Button>
													</Show>
												</Show>

												<p class="text-center text-sm text-neutral-700">
													<Switch fallback="Registration has closed.">
														<Match when={registrationState() === 'too-early'}>
															Registration opens on{' '}
															{dayjs(event().registrationStartDate!).format('MMMM D, YYYY [at] h:mm A z')}.
														</Match>
														<Match when={registrationState() === 'open'}>
															Registration closes on{' '}
															{dayjs(event().registrationEndDate).format('MMMM DD, YYYY [at] h:mm A z')}.
														</Match>
													</Switch>
												</p>
											</Section>

											<Show when={event().termsConditions}>
												<p class="text-center text-sm">
													<TextLink href={`/events/${props.params.slug!}/terms-and-conditions`}>
														Terms and Conditions
													</TextLink>
												</p>
											</Show>
										</div>
									</GridFive>
								</Grid>
							</Container>
						</>
					)}
				</Show>
			</Suspense>
		</ErrorBoundary>
	);
}

const getEvent = createContentfulRequest('events', {});

export const route = {
	info: { nav: { hero: true } },
	preload: async ({ params }) => getEvent(params.slug!),
} satisfies RouteDefinition;
