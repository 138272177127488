import { Heading } from '@troon/ui';
import { Dynamic } from 'solid-js/web';
import { useExperiment } from '@troon/analytics';
import { TroonGolfLandingPages as LayoutControl } from './_components/layout';
import { TroonGolfLandingPages as LayoutMap } from './_components/layout-map';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function TroonGolfCaliforniaLandingPage() {
	const branch = useExperiment('coop-page-layout');

	return (
		<Dynamic
			component={branch === 'map' ? LayoutMap : LayoutControl}
			title="Troon Golf California"
			image="troon-golf-california.png"
			facilities={facilities}
			location="California"
		>
			<Heading as="h1">
				Top<span class="sr-only"> Golf</span> Courses in California
			</Heading>
			<p>
				The Troon Golf collection of courses in California expand from the Palm Springs area, to the Southern California
				coastline, up to the hills of Northern California. Each course is woven into the natural terrain with a unique
				vision, infused with impeccable quality that is the Troon Golf hallmark.
			</p>
		</Dynamic>
	);
}

const facilities: ComponentProps<typeof LayoutControl>['facilities'] = {
	'alhambra-golf-course': { tel: '626.238.6559' },
	'aviara-golf-club': { tel: '858.351.0513' },
	'bayonet-and-black-horse': { tel: '831.200.0639' },
	'classic-club': { tel: '442.334.8249' },
	'indian-wells-golf-resort': {
		tel: '760.514.0250',
		url: 'https://indianwellstgcapp.ezlinksgolf.com/index.html#/search',
	},
	'maderas-golf-club': { tel: '858.602.3603' },
	'monarch-beach-golf-links': { tel: '949.336.3252' },
	'native-oaks-golf-club': { tel: '760.514.0252' },
	'shadow-hills-golf-club': { tel: '760.292.2809', url: 'https://shadowhillstgca.ezlinksgolf.com/index.html#/search' },
	'silverado-resort': { tel: '707.307.1201' },
	'teleli-golf-club': { tel: '209.728.6171', url: 'https://teleli-golf-club-ca.book.teeitup.com/' },
	'the-ridge-golf-and-event-center': { tel: '530.537.0284' },
	'tustin-ranch-golf-club': { tel: '714.497.1521' },
	'westin-rancho-mirage-golf-resort-and-spa': { tel: '760.558.4294' },
	'yocha-dehe-golf-club': { tel: '530.564.3062' },
};

export const route = { info: { nav: { sticky: false }, banner: { hide: true } } } satisfies RouteDefinition;
