import dayjs from '@troon/dayjs';
import { HttpStatusCode } from '@solidjs/start';
import { ArticleTile, Button, Container, Heading, HorizontalRule, Link, Page, Pagination, Section } from '@troon/ui';
import { createAsync, useParams } from '@solidjs/router';
import { createMemo, For, Show, Suspense } from 'solid-js';
import { Link as HeadLink, Title } from '@solidjs/meta';
import { IconArrowLeftMd } from '@troon/icons/arrow-left-md';
import { createContentfulListRequest } from '../../content/[model]/_client';
import { Hero } from '../../../components/hero/photo';
import type { RouteDefinition } from '@solidjs/router';
import type { Entry } from 'contentful';
import type { TypeEventSkeleton } from '@troon/contentful';

const perPage = 12;

export default function EventsPaginated() {
	const params = useParams<{ page?: string }>();
	const events = createAsync(
		async () => {
			const today = dayjs();
			const events = await getEvents({ skip: (parseInt(params.page ?? '1', 10) - 1) * perPage, limit: perPage });
			const upcoming: (typeof events)['items'] = [];
			const past: (typeof events)['items'] = [];
			for (const event of events.items) {
				if (dayjs(event.fields.startDate).isSameOrAfter(today)) {
					upcoming.push(event);
				} else {
					past.push(event);
				}
			}
			return { upcoming, past, total: events.items.length };
		},
		{ deferStream: true },
	);

	return (
		<>
			<Title>
				Events<Show when={params.page}>{(page) => ` | Page ${page()}`}</Show> | Troon
			</Title>
			<Hero src="https://images.ctfassets.net/rdsy7xf5c8dt/2enNmWsx0CleARWVB0ztxU/ed0a2d16fce8ddf161281ac585f9f412/b4d10513cbac9ecc14d989cae332f479.jpg">
				<Heading as="h1">Events</Heading>
			</Hero>
			<Show when={params.page === '1'}>
				<HeadLink rel="canonical" href="/events" />
			</Show>
			<Container>
				<Page>
					<Suspense>
						<Show when={events.latest?.upcoming.length ? events.latest?.upcoming : null}>
							{(events) => (
								<Section>
									<Heading as="h2" size="h3">
										Upcoming Events
									</Heading>
									<div class="flex flex-col divide-y divide-neutral">
										<For
											each={events()}
											fallback={
												<div class="flex flex-col gap-8">
													<HttpStatusCode code={404} />
													<p>No events found.</p>
													<Button class="w-fit" as={Link} href="/events">
														<IconArrowLeftMd />
														Back to Events
													</Button>
												</div>
											}
										>
											{(event, i) => <EventArticle event={event} eager={i() < 6} preload={i() < 3} />}
										</For>
									</div>
								</Section>
							)}
						</Show>
					</Suspense>

					<Suspense>
						<Show when={events.latest?.past.length ? events.latest?.past : null}>
							{(events) => (
								<Section>
									<Heading as="h2" size="h3">
										Past Events
									</Heading>
									<div class="flex flex-col divide-y divide-neutral">
										<For
											each={events()}
											fallback={
												<div class="flex flex-col gap-8">
													<HttpStatusCode code={404} />
													<p>No events found.</p>
													<Button class="w-fit" as={Link} href="/events">
														<IconArrowLeftMd />
														Back to Events
													</Button>
												</div>
											}
										>
											{(event, i) => <EventArticle event={event} eager={i() < 6} preload={i() < 3} />}
										</For>
									</div>
								</Section>
							)}
						</Show>
					</Suspense>

					<Suspense>
						<Show when={events.latest}>
							{(data) => (
								<>
									<HorizontalRule />
									<Pagination
										currentPage={parseInt(params.page ?? '1', 10)}
										lastPage={Math.ceil(data()!.total / perPage)}
										url={(page) => `/events/page/${page}`}
										nextTitle="Older"
										prevTitle="Newer"
									/>
								</>
							)}
						</Show>
					</Suspense>
				</Page>
			</Container>
		</>
	);
}

function EventArticle(props: {
	event: Entry<TypeEventSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>;
	eager?: boolean;
	preload: boolean;
}) {
	const image = createMemo(() => props.event.fields.hero?.fields);
	const displayDate = createMemo(() => {
		const start = props.event.fields.startDate;
		const end = props.event.fields.endDate;
		if (!start || !end) {
			return undefined;
		}

		const startDate = dayjs(start);
		const endDate = dayjs(end);
		if (startDate.isSame(endDate, 'day')) {
			return startDate.format('MMMM D, YYYY');
		}
		const startFormat = startDate.isSame(endDate, 'year') ? 'MMMM D' : 'MMMM D, YYYY';
		return `${startDate.format(startFormat)} – ${endDate.format('MMMM D, YYYY')}`;
	});

	return (
		<div class="py-8 first:pt-0 last:pb-0">
			<ArticleTile
				buttonLabel={<>View Event</>}
				hero={
					image()
						? {
								src: image()?.file?.url,
								alt: image()?.description ?? image()?.title ?? '',
								preload: props.preload,
								loading: props.eager ? 'eager' : 'lazy',
							}
						: undefined
				}
				url={`/events/${props.event.fields.slug}`}
				title={props.event.fields.title}
				subtitle={
					<span class="text-sm @3xl/article:mb-2">
						{displayDate()}
						<Show when={props.event.fields.locationName}>{(loc) => <> • {loc()}</>}</Show>
					</span>
				}
			>
				<p>{props.event.fields.summary}</p>
			</ArticleTile>
		</div>
	);
}

const getEvents = createContentfulListRequest('events');

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
