import { Heading } from '@troon/ui';
import { useExperiment } from '@troon/analytics';
import { Dynamic } from 'solid-js/web';
import { TroonGolfLandingPages as LayoutControl } from './_components/layout';
import { TroonGolfLandingPages as LayoutMap } from './_components/layout-map';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function TroonGolfArizonaLandingPage() {
	const branch = useExperiment('coop-page-layout');

	return (
		<Dynamic
			component={branch === 'map' ? LayoutMap : LayoutControl}
			title="Troon Golf Hawaii"
			image="troon-golf-hawaii.png"
			facilities={facilities}
			location="Hawaii"
		>
			<Heading as="h1">
				Top<span class="sr-only"> Golf</span> Courses in Hawaii
			</Heading>
			<p>
				Troon Golf courses in Hawaii are as unique as the islands themselves, each woven into the serene terrain with a
				unique vision and infused with the impeccable quality that is a Troon Golf hallmark.
			</p>
		</Dynamic>
	);
}

const facilities: ComponentProps<typeof LayoutControl>['facilities'] = {
	'kaanapali-golf-courses': { tel: '808.809.8266' },
	kapalua: { tel: '808.809.8488', url: 'https://www.chronogolf.com/en/club/5033/widget' },
	'kapolei-golf-club': { tel: '808.809.8509' },
	'mauna-lani-auberge-resorts-collection': { tel: '808.809.8542' },
	'ocean-course-at-hokuala': { tel: '808.809.8561', url: 'https://troonhihokuala.ezlinksgolf.com/index.html#/search' },
	'pearl-at-kalauao': { tel: '808.809.8821' },
	'princeville-makai-golf-club': { tel: '808.809.8826' },
	'the-king-kamehameha-golf-club': { tel: '808.809.8980' },
	'volcano-golf-course': { tel: '808.809.8982' },
};

export const route = { info: { nav: { sticky: false }, banner: { hide: true } } } satisfies RouteDefinition;
