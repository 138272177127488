import { Heading } from '@troon/ui';
import { useExperiment } from '@troon/analytics';
import { Dynamic } from 'solid-js/web';
import { TroonGolfLandingPages as LayoutControl } from './_components/layout';
import { TroonGolfLandingPages as LayoutMap } from './_components/layout-map';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function TroonGolfArizonaLandingPage() {
	const branch = useExperiment('coop-page-layout');

	return (
		<Dynamic
			component={branch === 'map' ? LayoutMap : LayoutControl}
			title="Troon Golf Arizona"
			image="troon-golf-arizona.png"
			facilities={facilities}
			location="Arizona"
		>
			<Heading as="h1">
				Top<span class="sr-only"> Golf</span> Courses in Arizona
			</Heading>
			<p>
				Troon Golf courses in Arizona are as diverse as the Southwest itself, each woven into the rugged desert terrain
				with a unique vision and infused with the impeccable quality that is Troon Golf hallmark.
			</p>
		</Dynamic>
	);
}

const facilities: ComponentProps<typeof LayoutControl>['facilities'] = {
	'ak-chin-southern-dunes-golf-club': { tel: '480.462.0383' },
	'boulders-resort-and-spa-scottsdale': { tel: '480.542.5271' },
	'dove-valley-ranch-golf-club': { tel: '480.542.7039' },
	'eagle-mountain-golf-club': { tel: '480.542.7040' },
	'foothills-golf-club': { tel: '480.569.1465' },
	'golf-club-of-estrella': { tel: '623.278.3247' },
	'longbow-golf-club': { tel: '480.569.1468' },
	'lookout-mountain-golf-club': { tel: '602.610.1354' },
	'power-ranch-golf-club': { tel: '480.569.1470' },
	'rancho-manana-golf-club': { tel: '480.569.1477' },
	'sedona-golf-resort': { tel: '928.239.7069' },
	'sterling-grove-golf-country-club': { tel: '623.278.4001' },
	'sunridge-canyon-golf-club': { tel: '480.956.4505', url: 'https://sunridge-canyon-golf-club-tgaz.book.teeitup.com' },
	'the-club-at-starr-pass': { tel: '520.338.2876' },
	'the-phoenician': { tel: '480.956.5167' },
	'the-westin-kierland-golf-club': { tel: '480.569.1507' },
	'troon-north-golf-club': { tel: '480.569.1510' },
	'tubac-golf-resort-and-spa': { tel: '520.338.2878' },
};

export const route = { info: { nav: { sticky: false }, banner: { hide: true } } } satisfies RouteDefinition;
