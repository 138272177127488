import './nav.css';
import { Match, Suspense, Switch, For, Show, createSignal, createUniqueId, createEffect, createMemo } from 'solid-js';
import {
	Avatar,
	DialogContent,
	Dialog,
	LinkButton,
	NavigationMenu,
	NavigationMenuItem,
	Button,
	Tag,
	Link,
	Picture,
	TextLink,
	Container,
	TroonAccess,
	TroonAccessPlusProduct,
	TroonAccessProduct,
} from '@troon/ui';
import { createVisibilityObserver } from '@solid-primitives/intersection-observer';
import { NavigationMenu as KNavigationMenu } from '@kobalte/core/navigation-menu';
import { Accordion as KAccordion } from '@kobalte/core/accordion';
import { Popover as KPopover } from '@kobalte/core/popover';
import { Dynamic } from 'solid-js/web';
import { createStore } from 'solid-js/store';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useBeforeLeave, useCurrentMatches } from '@solidjs/router';
import { IconStar } from '@troon/icons/star';
import { IconSearchMagnifyingGlass } from '@troon/icons/search-magnifying-glass';
import { IconBellRing } from '@troon/icons/bell-ring';
import { IconCalendar } from '@troon/icons/calendar';
import { IconTag } from '@troon/icons/tag';
import { IconHamburgerMd } from '@troon/icons/hamburger-md';
import { IconCloseMd } from '@troon/icons/close-md';
import { IconCaretDownMd } from '@troon/icons/caret-down-md';
import { IconUserCircle } from '@troon/icons/user-circle';
import { IconLogoSquare } from '@troon/icons/logo-square';
import { IconLogOut } from '@troon/icons/log-out';
import { IconMap } from '@troon/icons/map';
import { IconPrive } from '@troon/icons/prive';
import { IconFlag } from '@troon/icons/flag';
import { IconLogo } from '@troon/icons/logo';
import { useUser } from '../providers/user';
import { AuthFlow } from '../partials/auth/auth';
import { getConfigValue } from '../modules/config';
import { TroonCardSubscriptionProductType } from '../graphql';
import type { IconComponent } from '@troon/icons';
import type { Component, ComponentProps, ParentProps } from 'solid-js';

const userNav: Array<ComponentProps<typeof NavigationMenuItem> & { access?: boolean }> = [
	{ icon: IconCalendar, href: '/reservations', children: 'Reservations' },
	{ icon: IconBellRing, href: '/alerts', children: 'Tee time alerts' },
	{ icon: IconTag, href: '/access/benefits', children: 'Troon Access Benefits', access: true },
	{ icon: IconStar, href: '/courses/favorites', children: 'Favorites' },
];

export function Nav() {
	const user = useUser();
	const [authOpen, setAuthOpen] = createSignal(false);
	const authDialogId = createUniqueId();
	const [topTarget, setTopTarget] = createSignal<Element>();
	const [menuOpen, setMenuOpen] = createSignal(false);
	const menuId = createUniqueId();
	const matches = useCurrentMatches();
	const hasHero = createMemo(() => {
		return matches().some((match) => !!match.route.info?.nav?.hero);
	});
	const isNotSticky = createMemo(() => {
		return matches().some((match) => match.route.info?.nav?.sticky === false);
	});
	const isMinimal = createMemo(() => {
		return matches().some(
			(match) =>
				match.route.info?.nav?.appearance === 'minimal' || match.route.info?.nav?.appearance === 'extra-minimal',
		);
	});
	const isExtraMinimal = createMemo(() => {
		return matches().some((match) => match.route.info?.nav?.appearance === 'extra-minimal');
	});
	const isNoNav = createMemo(() => {
		return matches().some((match) => match.route.info?.nav?.appearance === 'none');
	});

	const [menuStore, setMenuStore] = createStore<{
		useHeroMode: boolean;
		openMenu: undefined | string;
	}>({
		// eslint-disable-next-line solid/reactivity
		useHeroMode: hasHero(),
		openMenu: undefined,
	});

	let nav: HTMLDivElement;

	createEffect(() => {
		setMenuStore('useHeroMode', !menuOpen() && !menuStore.openMenu && atTop() && hasHero());
	});

	// eslint-disable-next-line solid/reactivity
	const atTop = createVisibilityObserver({ threshold: 0.1 })(topTarget);

	useBeforeLeave(() => {
		resetMenu();
	});

	function resetMenu() {
		setMenuStore({
			openMenu: undefined,
			useHeroMode: hasHero() && atTop(),
		});
		setMenuOpen(false);
	}

	let mouseLeaveTimeout: NodeJS.Timeout;

	return (
		<Show when={!isNoNav()}>
			<div ref={setTopTarget} class="pointer-events-none absolute inset-x-0 top-0 h-6" />
			<div
				ref={nav!}
				class="inset-x-0 top-0 z-40"
				classList={{ sticky: !isNotSticky() }}
				/**
				 * Hacks: Somewhere deep in Kobalte, when using `sticky` position and including a page scroll padding
				 * Resetting the page scroll position after the menu animates (in or out?), the scroll padding
				 * causes the page to jump around. We can safely add and remove the class to prevent his.
				 */
				onMouseEnter={() => {
					document.documentElement.classList.remove('scroll-pt-24');
					clearTimeout(mouseLeaveTimeout);
				}}
				onMouseLeave={() => {
					clearTimeout(mouseLeaveTimeout);
					mouseLeaveTimeout = setTimeout(() => {
						document.documentElement.classList.add('scroll-pt-24');
					}, 500);
				}}
			>
				<nav
					role="navigation"
					class="relative -mb-16 h-16 border-b transition-colors duration-100"
					classList={{
						'border-b-white/20 bg-white/0 text-white': menuStore.useHeroMode,
						'border-b-neutral-500 bg-white text-neutral-900': !menuStore.useHeroMode,
						shadow: !atTop(),
					}}
				>
					<Container class="relative flex h-full flex-row items-center justify-stretch gap-x-4">
						<Show when={!isMinimal()}>
							<KPopover
								fitViewport
								modal
								preventScroll
								sameWidth={false}
								placement="bottom"
								gutter={15}
								open={menuOpen()}
								onOpenChange={(open) => {
									setMenuOpen(open);
								}}
							>
								<KPopover.Trigger
									as={Button}
									aria-haspopup="dialog"
									aria-expanded={menuOpen()}
									aria-controls={menuOpen() ? menuId : undefined}
									appearance="transparent-current"
									size="sm"
									class="shrink grow-0 rounded-md px-2 py-1 text-xl xl:hidden"
								>
									<Show
										when={menuOpen()}
										fallback={
											<>
												<IconHamburgerMd class="shrink-0" />
												<span class="sr-only">Show site navigation</span>
											</>
										}
									>
										<IconCloseMd class="shrink-0" />
										<span class="sr-only">Hide site navigation</span>
									</Show>
								</KPopover.Trigger>
								<KPopover.Portal>
									<KPopover.Content
										// eslint-disable-next-line tailwindcss/no-arbitrary-value
										class="fixed -start-2 z-40 flex h-[calc(var(--kb-popper-content-available-height)+0.55rem)] w-screen flex-col justify-between overflow-hidden bg-white animate-out fade-out slide-out-to-bottom anim-duration-500 ui-expanded:duration-700 ui-expanded:animate-in ui-expanded:fade-in ui-expanded:slide-in-from-bottom ui-expanded:anim-duration-200"
									>
										<KPopover.Title class="sr-only">Site navigation</KPopover.Title>
										<div class="grow overflow-x-auto">
											<KAccordion
												as="nav"
												collapsible
												onChange={(value) => {
													setMenuStore('openMenu', value[0] ?? undefined);
												}}
											>
												<For each={navItems}>
													{(item) => (
														<Show
															when={item.items}
															fallback={
																<Link
																	href={item.href}
																	class="flex w-full items-center justify-between rounded-none border-b border-s-4 border-transparent border-b-neutral px-4 py-6 transition-colors duration-200"
																	onClick={resetMenu}
																>
																	<span>{item.title}</span>
																	<Show when={item.tag}>
																		<Tag>{item.tag}</Tag>
																	</Show>
																</Link>
															}
														>
															<KAccordion.Item value={item.title} class="border-b border-neutral">
																<KAccordion.Header>
																	<KAccordion.Trigger class="group flex w-full items-center justify-between border-s-4 border-transparent px-4 py-6 outline-none transition-colors duration-200 focus-visible:ring-1 focus-visible:ring-brand-500 focus-visible:ring-offset-2 ui-expanded:border-brand ui-expanded:bg-brand-100">
																		<span>{item.title}</span>
																		<Show when={item.tag}>
																			<Tag>{item.tag}</Tag>
																		</Show>
																		<Show when={item.items}>
																			<IconCaretDownMd
																				class={twJoin(
																					'transition-all duration-200',
																					menuStore.openMenu === item.title && 'rotate-180 text-brand',
																				)}
																			/>
																		</Show>
																	</KAccordion.Trigger>
																</KAccordion.Header>

																<KAccordion.Content
																	// eslint-disable-next-line tailwindcss/no-arbitrary-value
																	class="animate-[accordionOut_200ms_ease-in-out] overflow-clip ui-expanded:animate-[accordionIn_200ms_ease-in-out]"
																>
																	<Show when={'items' in item && item.items}>
																		{(subItems) => (
																			<ul class="flex flex-col gap-2">
																				<For each={subItems()}>
																					{(subItem) => (
																						<Show when={!subItem.img && !subItem.customWrapper}>
																							<li>
																								<Link
																									href={subItem.href}
																									class="relative flex h-full grow flex-col gap-2 overflow-hidden rounded-none p-4 ps-6 outline-none hover:bg-brand-100 focus-visible:bg-brand-100 focus-visible:ring-0 aria-current-page:bg-brand-100/50"
																									onClick={resetMenu}
																								>
																									<span class="flex gap-2">
																										<Show when={subItem.icon}>
																											{(icon) => (
																												<Dynamic
																													component={icon()}
																													class="z-10 shrink-0 grow-0 text-lg text-brand"
																												/>
																											)}
																										</Show>
																										<span class={twJoin('z-10 flex h-full grow flex-col gap-1')}>
																											<span class="font-semibold capitalize">
																												{typeof subItem.title === 'function'
																													? subItem.title({})
																													: subItem.title}
																											</span>
																											<Show when={subItem.description}>
																												{(desc) => (
																													<span class="text-sm font-medium text-neutral-800">
																														{desc()}
																													</span>
																												)}
																											</Show>
																										</span>
																									</span>
																									<Show when={subItem.button}>
																										{(button) => (
																											<span class="shrink grow-0 pt-4">
																												<Button
																													{...button()}
																													as="button"
																													role="presentation"
																													class="w-fit before:absolute before:inset-0"
																												>
																													{button().children}
																												</Button>
																											</span>
																										)}
																									</Show>
																								</Link>
																							</li>
																						</Show>
																					)}
																				</For>
																				<Show when={item.banner}>
																					{(banner) => (
																						<li
																							role="presentation"
																							class="relative w-full bg-brand p-4 text-white transition-colors duration-200 hover:bg-brand-600"
																						>
																							<p class="flex flex-col items-center justify-center gap-2 text-center">
																								<span>{banner().text}</span>
																								<TextLink
																									class="text-current underline before:absolute before:inset-0"
																									href={banner().href}
																									onClick={resetMenu}
																								>
																									{banner().link}
																								</TextLink>
																							</p>
																						</li>
																					)}
																				</Show>{' '}
																			</ul>
																		)}
																	</Show>
																</KAccordion.Content>
															</KAccordion.Item>
														</Show>
													)}
												</For>
											</KAccordion>
										</div>
										<Show when={!user()}>
											<ul class="flex items-center justify-center gap-4 border-t border-neutral py-4 leading-3">
												<li class="border-r border-r-neutral pe-4">
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														<span class="text-neutral-950">Sign up</span>
													</AuthButton>
												</li>
												<li>
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														<IconUserCircle class="-my-1 text-xl text-brand" />
														<span class="text-neutral-950">Log in</span>
													</AuthButton>
												</li>
											</ul>
										</Show>
									</KPopover.Content>
								</KPopover.Portal>
							</KPopover>
						</Show>

						<div
							class={twJoin(
								'flex grow items-center sm:justify-start xl:grow-0',
								isMinimal() ? 'justify-start' : 'justify-center',
							)}
							classList={{
								'text-neutral-900': !menuStore.useHeroMode,
							}}
						>
							<LinkButton appearance="current" href="/" size="sm">
								<IconLogo class="w-28" aria-label="Troon logo" />
								<span class="sr-only">Troon home</span>
							</LinkButton>
						</div>

						<div class="hidden grow xl:block">
							<Show when={!isMinimal()}>
								<KNavigationMenu
									class="flex gap-2"
									delayDuration={0}
									skipDelayDuration={500}
									gutter={16}
									onValueChange={(value) => {
										if (!value) {
											setMenuStore('openMenu', undefined);
										}
									}}
								>
									<For each={navItems}>
										{(item) => (
											<KNavigationMenu.Menu
												modal={false}
												preventScroll
												onOpenChange={(open) => {
													if (open) {
														setMenuStore('openMenu', item.title);
													}
												}}
											>
												<KNavigationMenu.Trigger
													as={LinkButton}
													appearance="current"
													href={item.href}
													onClick={
														'items' in item
															? (e) => {
																	if (!e.metaKey && !e.shiftKey && !e.ctrlKey) {
																		e.preventDefault();
																	}
																}
															: undefined
													}
													class="appearance-none font-medium capitalize focus-visible:bg-current/10 focus-visible:ring-0 aria-disabled:cursor-default"
												>
													{item.title}
													<Show when={item.tag}>
														<Tag>{item.tag}</Tag>
													</Show>
													<Show when={'items' in item}>
														<KNavigationMenu.Icon class="transition-transform duration-200 ui-expanded:rotate-180">
															<IconCaretDownMd />
														</KNavigationMenu.Icon>
													</Show>
												</KNavigationMenu.Trigger>
												<Show
													when={'items' in item && item.items}
													fallback={
														<KNavigationMenu.Portal>
															<KNavigationMenu.Content class="absolute h-px overflow-hidden bg-neutral-500 opacity-0" />
														</KNavigationMenu.Portal>
													}
												>
													{(subItems) => (
														<KNavigationMenu.Portal>
															<>
																<KNavigationMenu.Content
																	onOpenAutoFocus={() => {
																		setMenuStore('openMenu', item.title);
																	}}
																	class={twJoin(
																		'container pointer-events-none absolute inset-x-0 top-0 mx-auto grid w-full origin-left grid-flow-row grid-cols-3 gap-x-24 gap-y-4 px-4 py-11 outline-none ease-in-out anim-duration-200 ui-enter:animate-in ui-enter:fade-in ui-exit:animate-out ui-exit:fade-out ui-expanded:pointer-events-auto motion-safe:ui-enter-start:slide-in-from-left motion-safe:ui-enter-end:slide-in-from-right motion-safe:ui-exit-start:slide-out-to-left motion-safe:ui-exit-end:slide-out-to-right sm:px-6 md:px-12',
																		item.banner && 'pb-24',
																		...subItems()
																			.filter((subItem) => subItem.grid)
																			.map(({ grid }) => grid),
																	)}
																>
																	<For each={subItems()}>{(subItem) => <NavigationItem item={subItem} />}</For>
																	<Show when={item.banner}>
																		{(banner) => (
																			<li
																				role="presentation"
																				// eslint-disable-next-line tailwindcss/no-arbitrary-value
																				class="absolute inset-x-[-999px] bottom-0 bg-brand py-4 text-white transition-colors duration-200 hover:bg-brand-600"
																			>
																				<p class="container mx-auto flex justify-center gap-2 px-4 sm:px-6 md:px-12">
																					<span>{banner().text}</span>
																					<KNavigationMenu.Item
																						as={TextLink}
																						class="text-current underline before:absolute before:inset-0"
																						href={banner().href}
																					>
																						{banner().link}
																					</KNavigationMenu.Item>
																				</p>
																			</li>
																		)}
																	</Show>
																</KNavigationMenu.Content>
															</>
														</KNavigationMenu.Portal>
													)}
												</Show>
											</KNavigationMenu.Menu>
										)}
									</For>
									<KNavigationMenu.Viewport
										// eslint-disable-next-line tailwindcss/no-arbitrary-value
										class="z-50 -ms-2 flex w-screen origin-[var(--kb-menu-content-transform-origin)] items-center justify-center overflow-clip bg-transparent text-neutral-950 shadow-lg outline-none ease-in animate-out fade-out anim-duration-200 fill-mode-forwards ui-expanded:h-[var(--kb-navigation-menu-viewport-height)] ui-expanded:bg-white ui-expanded:opacity-100 ui-expanded:ease-out ui-expanded:animate-in ui-expanded:fade-in ui-expanded:fill-mode-none motion-safe:transition-all motion-safe:duration-200 motion-safe:slide-out-to-top-32 motion-safe:ui-expanded:slide-in-from-top"
									/>
								</KNavigationMenu>
							</Show>
						</div>

						{/* Profile dropdown */}
						<div
							class={twJoin(
								'flex shrink grow-0 flex-row items-center transition-opacity duration-100 xl:ml-3',
								menuOpen() && 'pointer-events-none opacity-0',
							)}
						>
							<Suspense>
								<Switch>
									<Match when={isExtraMinimal()}>{null}</Match>
									<Match when={user()}>
										{(user) => (
											<ul class="flex items-center gap-4 leading-3">
												<Show when={!isMinimal()}>
													<li class="hidden border-r border-r-current pe-4 sm:block">
														<LinkButton href="/reservations" appearance="current">
															<IconCalendar
																class="-my-1 text-xl transition-colors duration-100"
																classList={{
																	'text-current': menuStore.useHeroMode,
																	'text-brand': !menuStore.useHeroMode,
																}}
															/>
															<span class="sr-only sm:not-sr-only">My reservations</span>
														</LinkButton>
													</li>
												</Show>
												<li>
													<NavigationMenu
														showIcon={false}
														sameWidth={false}
														placement="bottom-end"
														trigger={() => (
															<LinkButton
																href="/account"
																appearance="current"
																onClick={(e) => e.preventDefault()}
																size="sm"
															>
																<Avatar
																	firstName={user().me.firstName}
																	lastName={user().me.lastName}
																	aria-label="Open user menu"
																	class="size-8 transition-all duration-100"
																	classList={{
																		'text-current': menuStore.useHeroMode,
																		'text-brand': !menuStore.useHeroMode,
																	}}
																/>
																<IconCaretDownMd class="hidden md:inline-flex" />
															</LinkButton>
														)}
													>
														<KNavigationMenu.Item
															as={Link}
															href="/account"
															class="relative -mx-3 -mt-3 flex flex-col items-center gap-6 rounded-b-none rounded-t bg-gradient-to-r from-neutral-950 to-brand-700 px-3 py-6 text-center text-white outline-none sm:min-w-96"
															closeOnSelect
														>
															<Switch>
																<Match
																	when={
																		user()?.me.troonAccessProductType ===
																		TroonCardSubscriptionProductType.TroonAccessPlus
																	}
																>
																	<TroonAccessPlusProduct current class="absolute right-4 top-4 w-20 text-white" />
																</Match>
																<Match when={user()?.me.troonAccessProductType}>
																	<TroonAccessProduct current class="absolute right-4 top-4 w-20 text-white" />
																</Match>
															</Switch>
															<img
																src="/assets/images/topo-bg-bottom.svg"
																alt=""
																class="absolute inset-x-0 bottom-0 z-0"
																loading="lazy"
															/>

															<div class="z-10 flex flex-col items-center gap-2">
																<Avatar
																	firstName={user().me.firstName}
																	lastName={user().me.lastName}
																	class="mb-2 size-16"
																/>
																<span>
																	{user().me.firstName} {user().me.lastName}
																</span>
																<span class="text-sm">Troon Rewards #{user().me.troonRewardsId}</span>
															</div>
															<Button size="sm" class="z-10">
																View account
															</Button>
														</KNavigationMenu.Item>
														<For each={userNav}>
															{(item) => (
																<Show when={!item.access || user()?.me.troonAccessProductType}>
																	<NavigationMenuItem {...item} />
																</Show>
															)}
														</For>
														<NavigationMenuItem icon={IconLogOut} href="/auth/logout">
															Log out
														</NavigationMenuItem>
													</NavigationMenu>
												</li>
											</ul>
										)}
									</Match>

									<Match when={user() === null}>
										<>
											<ul class="flex items-center gap-4 leading-3">
												<li class="hidden border-r border-r-current pe-4 sm:block">
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														Sign up
													</AuthButton>
												</li>
												<li>
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														<IconUserCircle
															class="-my-1 shrink-0 text-xl"
															classList={{
																'text-current': menuStore.useHeroMode,
																'text-brand': !menuStore.useHeroMode,
															}}
														/>
														<span class="sr-only whitespace-nowrap text-nowrap sm:not-sr-only">Log in</span>
													</AuthButton>
												</li>
											</ul>
											<Dialog key="auth-nav" open={authOpen()} onOpenChange={setAuthOpen} id={authDialogId}>
												<DialogContent width="fit" noPadding closeButton={false}>
													<AuthFlow onComplete={() => setAuthOpen(false)} />
												</DialogContent>
											</Dialog>
										</>
									</Match>
								</Switch>
							</Suspense>
						</div>
					</Container>
				</nav>
			</div>
			<Show when={!hasHero()}>
				<div class="h-16" />
			</Show>
		</Show>
	);
}

function AuthButton(props: ParentProps<{ open: boolean; id: string; setOpen: (o: boolean) => void }>) {
	return (
		<LinkButton
			aria-haspopup="dialog"
			aria-expanded={props.open}
			aria-controls={props.open ? `${props.id}-content` : undefined}
			onClick={(e) => {
				e.preventDefault();
				props.setOpen(true);
			}}
			appearance="current"
			href="/auth"
			class="flex-nowrap whitespace-nowrap text-nowrap"
		>
			{props.children}
		</LinkButton>
	);
}

function NavigationItem(props: { item: NavItem }) {
	return (
		<KNavigationMenu.Item
			as={Link}
			href={props.item.href}
			class={twJoin(
				'group/item relative flex h-full grow gap-2 overflow-hidden rounded p-4 transition-colors duration-100 hover:bg-brand-100 focus-visible:bg-brand-100 focus-visible:ring-0 aria-current-page:bg-brand-100/50',
				props.item.img && 'text-white',
			)}
			closeOnSelect
		>
			<Show when={props.item.img}>
				{(img) => (
					<>
						<Picture
							src={img()}
							width={640}
							height={480}
							sizes="20vw"
							alt=""
							class="absolute inset-0 -z-0 size-full object-cover group-hover/item:scale-105 motion-safe:transition-transform motion-safe:duration-150"
						/>
						<span class="absolute inset-0 z-0 bg-gradient-to-t from-black/80 to-black/0" />
					</>
				)}
			</Show>
			<Dynamic component={props.item.icon} class="z-10 shrink-0 grow-0 text-lg text-brand" />
			<Dynamic
				component={props.item.customWrapper ?? 'span'}
				class={twJoin('z-10 flex h-full grow flex-col gap-2', props.item.img && 'justify-end')}
			>
				<KNavigationMenu.ItemLabel class={twJoin('font-semibold capitalize', props.item.button && 'text-xl')}>
					{typeof props.item.title === 'function' ? props.item.title({}) : props.item.title}
				</KNavigationMenu.ItemLabel>
				<Show when={props.item.description}>
					{(desc) => (
						<KNavigationMenu.ItemDescription
							class={twJoin(
								!props.item.customWrapper && 'text-sm font-medium',
								!props.item.img && !props.item.customWrapper && 'text-neutral-800',
							)}
						>
							{desc()}
						</KNavigationMenu.ItemDescription>
					)}
				</Show>
				<Show when={props.item.button}>
					{(button) => (
						<span class="shrink grow-0 pt-4">
							<Button {...button()} as="button" role="presentation" class="w-fit before:absolute before:inset-0">
								{button().children}
							</Button>
						</span>
					)}
				</Show>
			</Dynamic>
		</KNavigationMenu.Item>
	);
}

type NavItem = {
	title: Component | string;
	href: string;
	description?: string;
	icon?: IconComponent;
	img?: string;
	grid?: string;
	button?: Pick<ComponentProps<typeof Button>, 'appearance' | 'size' | 'children'>;
	accessFlag?: boolean;
	customWrapper?: Component<ParentProps>;
};

type NavSection = {
	title: string;
	href: string;
	tag?: string;
	items?: Array<NavItem>;
	banner?: {
		text: string;
		link: string;
		href: string;
	};
};

const navItems: Array<NavSection> = [
	{
		title: 'Play Troon',
		href: '/tee-times',
		items: [
			{
				title: 'Find a Tee Time',
				description: 'Compare tee times and always find the best rate to play',
				icon: IconSearchMagnifyingGlass,
				href: '/tee-times',
			},
			{
				title: 'Troon Rewards',
				description: 'Earn points with every round you play to unlock complimentary rounds',
				icon: IconStar,
				href: '/rewards',
			},
			{
				title: () => (
					<>
						<span class="sr-only">Troon Access</span>
						<TroonAccess class="w-1/2" />
					</>
				),
				description: 'Save up to 50% on tee times at 150+ courses with the all-new Troon Access program',
				href: '/access',
				img: '',
				customWrapper: (props: ParentProps) => (
					<div class="absolute inset-0 z-10 flex size-full h-full grow flex-col justify-end gap-4 overflow-hidden rounded bg-gradient-to-r from-neutral-950 to-brand-700 p-6 text-base text-white">
						<IconLogoSquare class="absolute -right-12 -top-16 -z-0 size-64 origin-center rotate-12 text-brand opacity-20 group-hover/item:scale-110 motion-safe:transition-transform motion-safe:duration-100" />
						{props.children}
					</div>
				),
				grid: '[&>*:nth-child(3)]:row-span-3',
				accessFlag: true,
			},
			{
				title: 'Course Directory',
				description: 'Browse through our network of over 900 courses',
				icon: IconMap,
				href: '/courses',
			},
			{
				title: 'Events',
				description: 'Unlock a world of exclusive benefits, unforgettable experiences and unrivaled savings.',
				icon: IconCalendar,
				href: '/events',
			},
			{
				title: 'Troon Privé',
				description: 'Extraordinary golf and lifestyle benefits for private club members',
				icon: IconPrive,
				href: '/member-programs',
			},
			{
				title: 'Play Troon Programs',
				description: 'Initiatives and programs to help you and your family play more',
				icon: IconFlag,
				href: '/play-troon-programs',
			},
		],
	},
	{
		title: 'Troon Access',
		href: '/access',
		tag: 'New',
	},
	{
		title: 'Management Services',
		href: '/management-services',
		items: [
			{
				title: 'Management services',
				description:
					'Troon started as one facility in 1990 and has since grown to become the world’s largest professional club management company. Today, we provide services to 900+ locations and manage various amenities, such as racquet sports, aquatics, fitness, food & beverage, lodging, homeowners associations, and more.',
				href: '/management-services',
				grid: '[&>*:nth-child(1)]:row-span-3',
				button: {
					appearance: 'primary',
					size: 'sm',
					children: (
						<>
							Learn more<span class="sr-only"> about our management services</span>
						</>
					),
				},
			},
			{
				title: 'Private clubs',
				description: 'Enhance your private club member experience',
				href: '/management-services/private-club',
			},
			{
				title: 'Community Associations',
				description: 'Integrate a hospitality-centric approach to your community',
				href: '/management-services/community-associations',
			},
			{
				title: 'Daily fee & Resort',
				description: 'Enhance your guest experience and grow your club’s revenue',
				href: '/management-services/daily-fee-and-resort',
			},
			{
				title: 'Advisory Services',
				description: 'An alternative to professional management solutions',
				href: '/management-services/advisory-services',
			},
			{
				title: 'Municipal Properties',
				description: 'Improve your course conditions and reduce your costs',
				href: '/management-services/municipal',
			},
			{
				title: 'Troon Partners Network',
				description: 'Establish a brand identity among golf’s most influential brands',
				href: '/management-services/troon-partners-network',
			},
		],
	},
	{
		title: 'About Us',
		href: '/about',
		items: [
			{
				title: 'About Troon',
				description:
					'Troon started as one facility in 1990 and has since grown to become the world’s largest professional club management company',
				href: '/about',
			},
			{
				title: 'Team Troon',
				description: 'A collection of top international golf talent representing the Troon brand',
				href: '/about/team-troon',
			},
			{
				title: 'Join our team!',
				description: 'Discover your path in a career with Troon',
				href: '/about/career-opportunities',
				img: `${getConfigValue('IMAGE_HOST')}/web/hero/career.jpeg`,
				grid: '[&>*:nth-child(3)]:row-span-3',
			},
			{
				title: 'Career opportunities',
				description: 'Discover your path in a career with Troon',
				href: '/about/career-opportunities',
			},
			{
				title: 'Contact Us',
				description: 'Have a question? Get in touch',
				href: '/about/contact',
			},
			{
				title: 'Diversity, Equity, and Inclusion',
				description: 'Integrate a hospitality-centric approach to your community',
				href: '/about/dei',
			},
		],
	},
];
